import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo-rando"
import Layout from "../components/layout"
import "../styles/main.scss"

import SnapAlbum from "../components/snapalbum"

const RandoPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Trekathlon"
        descriptiom="Descriptions de randonnées et balades"
        keywords={[
          `Trekking`,
          `Travel`,
          `Tips`,
          `Budget`,
          `Photography`,
          `Travel`,
        ]}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <section>
        <div class="myalbums">
          {data.allImageSharp.group.map(({ fieldValue }) => (
            <Link to={"/albums/" + fieldValue}>
              <SnapAlbum filename={"album_" + fieldValue} alt={fieldValue} />
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default RandoPage

export const query = graphql`
  query {
    allImageSharp(
      filter: {
        fields: {
          exif: { meta: { album: { ne: "none" }, title: { ne: null } } }
        }
      }
      sort: { fields: fields___exif___meta___album }
    ) {
      group(field: fields___exif___meta___album) {
        fieldValue
      }
    }
  }
`
